import React from "react"
import styled from "styled-components"

// Components
import ContentWrapper from "./ContentWrapper"
import ContactBlock from "./ContactBlock"

// Images
import lineImage from "../images/line.svg"

// Types
import { IFooter } from "../types/components/footer"

const Footer = ({ title, email, phone, linkedin }: IFooter) => (
  <Container>
    <ContentWrapper>
      <Line src={lineImage} alt="line" />
      <ContactBlock
        title={title}
        email={email}
        phone={phone}
        linkedin={linkedin}
      />
    </ContentWrapper>
  </Container>
)

const Container = styled.footer`
  padding-bottom: 120px;
`

const Line = styled.img`
  width: 100%;
  margin-bottom: 24px;
`

export default Footer
