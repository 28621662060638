import React from "react"
import styled from "styled-components"

// Styles
import colors from "../styles/colors"
import { fastTransition } from "../styles/animations"
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

interface IProps {
  title: string
  email: string
  phone: {
    label: string
    number: string
  }
  linkedin: {
    label: string
    url: string
  }
  inverted?: boolean
  className?: string
}

const ContactBlack = ({
  title,
  email,
  phone,
  linkedin,
  inverted = false,
  className,
}: IProps) => (
  <div className={className}>
    <Title>{title}</Title>
    <Contact>
      <Link href={`mailto:${email}`} inverted={inverted}>
        💌 {email}
      </Link>
      <Link href={`tel:${phone.number}`} inverted={inverted}>
        ☎️ {phone.label}
      </Link>
      <Link href={linkedin.url} target="_blank" inverted={inverted}>
        👩🏽‍💻 {linkedin.label}
      </Link>
    </Contact>
  </div>
)

const Title = styled.h4<any>`
  ${textStyles.title};
  margin-bottom: 24px;

  ${mq.from.M`
    margin-bottom: 32px;
  `}
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.from.M`
    flex-direction: row;
  `}
`

const Link = styled.a`
  position: relative;
  margin-bottom: 16px;
  color: ${({ inverted }: { inverted: boolean }) =>
    inverted ? colors.rainforrest : colors.caribbean};
  text-decoration: none;

  :last-child {
    margin-bottom: 0;
  }

  ${mq.from.M`
    margin-right: 32px;
    margin-bottom: 0;

    :last-child {
      margin-right: 0;
    }
  `}

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ inverted }: { inverted: boolean }) =>
      inverted ? colors.rainforrest : colors.caribbean};
    transform: scale(0);
    transition: transform ${fastTransition};
  }

  :hover:after {
    transform: scale(1);
  }
`

export default ContactBlack
