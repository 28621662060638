import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

// Styling
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Components
import SEO from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import Footer from "../components/Footer"

// Types
import { INotFoundPage } from "../types/pages/404"

const NotFoundPage = ({ footer }: INotFoundPage) => (
  <>
    <SEO title="Page not found" />
    <Container>
      <Content>
        <ContentWrapper size="small">
          <Title>Hmm it looks like this page doesn't exist (anymore)...</Title>
          <Text>
            Let me guide you back to the <Link to="/">homepage</Link>.
          </Text>
        </ContentWrapper>
      </Content>
      <Footer {...footer} />
    </Container>
  </>
)

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const Content = styled.div`
  margin: auto;
  padding: 96px 0;

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const Title = styled.h1`
  ${textStyles.title};
`

const Text = styled.p`
  :last-child {
    margin-bottom: 0;
  }
`

export const query = graphql`
  query notFoundPage {
    prismicHomepage {
      data {
        # Footer
        footer_title {
          text
        }
      }
    }

    # Contact
    prismicContact {
      data {
        email_address
        phone_label
        phone_number
        linkedin_label
        linkedin_url
      }
    }
  }
`

export default ({ data }: any) => {
  const rawData = {
    page: {
      ...data.prismicHomepage.data,
    },
    contact: {
      ...data.prismicContact.data,
    },
  }

  const footer = {
    title: rawData.page.footer_title.text,
    email: rawData.contact.email_address,
    phone: {
      label: rawData.contact.phone_label,
      number: rawData.contact.phone_number,
    },
    linkedin: {
      label: rawData.contact.linkedin_label,
      url: rawData.contact.linkedin_url,
    },
  }

  return <NotFoundPage footer={footer} />
}
